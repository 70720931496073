import enLocale from './en';
import deLocale from './de';

export default {
  en: {
    translation: enLocale
  },
  de: {
    translation: deLocale
  }
};
