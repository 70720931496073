import { createValueContext } from './useContextValue';

const a11yState = createValueContext(
  () => window.localStorage.getItem('a11yState') === 'true',
  (value) => window.localStorage.setItem('a11yState', JSON.stringify(value)),
);

export const A11yStateProvider = a11yState.ContextValueProvider;

export const useA11yState = a11yState.useContextValue;
