import { createValueContext } from './useContextValue';

const auth = createValueContext(
  () => window.localStorage.getItem('authenticated') === 'true',
  (value) =>
    window.localStorage.setItem('authenticated', JSON.stringify(value)),
);

export const AuthProvider = auth.ContextValueProvider;

export const useAuth = auth.useContextValue;
